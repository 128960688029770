/*
    DEMO STYLE
*/

#sidebarCollapse {
  background: transparent;
  border-color: transparent;
  box-shadow: none !important;
  position: absolute;
  width: 46px;
  height: 46px;
}

.rotate {
  transform: rotate(180deg);
  transition: 0.5s;
}

.texto-body {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

.nav-item-menu,
.nav-item-menu:hover,
.nav-item-menu:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  background: #23447e;
  color: #fff;
  transition: all 0.3s;
  display: flex;
  /**width: 100%;**/
  flex-direction: column;
}

#sidebar.active {
  min-width: 0px;
  max-width: 0px;
  text-align: center;
}

#sidebar ul li .nav-item-menu {
  text-align: left;
  /**width: 225px;**/
}

#sidebar.active ul li {
  overflow: hidden;
}

#sidebar.active ul li .nav-item-menu {
  padding: 20px 0px 20px 30px;
  font-size: 0.85em;
}

#sidebar.active ul li .nav-item-menu .img-item-menu {
  width: 15px;
}

#sidebar.active ul li .nav-item-menu .nav-text {
  display: none;
}

#sidebar.active ul li .nav-item-menu i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

#sidebar.active ul ul .nav-item-menu {
  padding: 10px !important;
}

#sidebar ul li .nav-item-menu {
  padding: 0.5rem 1rem;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li .nav-item-menu.entrega-menu {
  padding-left: 0.75rem !important;
}

#sidebar.active ul li .nav-item-menu.entrega-menu {
  padding-left: 1.5rem !important;
}

#sidebar ul li .nav-item-menu i {
  margin-right: 10px;
}

#sidebar ul li.active > .nav-item-menu,
.nav-item-menu[aria-expanded="true"] {
  color: #fff;
  background: #ff751a;
}

.nav-item-menu[data-toggle="collapse"] {
  position: relative;
}

ul ul .nav-item-menu {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #ff751a;
}

#sidebar ul li .nav-item-menu .nav-text {
  font-size: 14px;
  padding-left: 10px;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  overflow: auto;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  #sidebar.active {
    margin-left: 0 !important;
  }
  #sidebar ul li .nav-item-menu {
    padding: 20px 10px;
  }
  #sidebar ul li .nav-item-menu span {
    font-size: 0.85em;
  }
  #sidebar ul li .nav-item-menu i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul .nav-item-menu {
    padding: 10px !important;
  }
  #sidebar ul li .nav-item-menu i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
