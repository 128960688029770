.col-login--image {
  padding-right: 0px;
  padding-left: 0px;
  background-image: url("../images/login-4.jpg");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.col-login-form {
  padding: 70px;
}

.col-login-form form {
  padding: 40px 5px 0px 10px;
}

.bg-fondo-primary {
  background: #eaf1fc;
}

.bg-gray {
  background-color: #f0f0f0;
}

.bg-warn-blue {
  background-color: #9fb3d4;
}

.bg-blue {
  background-color: #c1cfea;
}

.bg-soft-blue {
  background-color: #eaf0fc;
}

.bg-soft-gray {
  background-color: #d1cfd0;
}

.menu-left {
  background-color: #23447e;
  min-height: 100vh;
}

.menu-left .menu-left--image {
  height: 160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 230px;
}

.logo-link {
  text-align: center;
}

.menu-left .menu-left--image img {
  width: 85%;
  height: 100%;
  object-fit: scale-down;
}

.bg-orange {
  background-color: #ff751a;
}

.menu-left .menu-left--navbar a {
  color: #fff;
}

.bg-top-blue {
  background-color: #2880e4;
}

.menu-right--title-panel p {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  padding: 12px 30px;
  margin-bottom: 0px;
}

.menu-right--title-user p {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  padding: 12px 30px;
  margin-bottom: 0px;
}

.border-hr {
  border: 1px solid #ffffff;
  opacity: 0.2;
}

.panel-contenedor--info {
  padding: 15px;
}

label {
  font-weight: 600;
  font-size: 0.85rem;
  margin-bottom: 0.1rem;
}

.btn-warning {
  color: #fff;
  background-color: #ff751a;
  border-color: #ff751a;
}

.btn-warning:hover {
  color: #fff;
  background-color: #f39c63;
  border-color: #f39c63;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f39c63;
  border-color: #f39c63;
}

.btn-warning.focus,
.btn-warning:focus {
  color: #fff;
  background-color: #f39c63;
  border-color: #f39c63;
  box-shadow: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #4f91db;
  background-color: #fff;
  border-color: #ffffff #ffffff #fffbfb;
  font-weight: 600;
  border-bottom: 2px solid #4f91db;
}

.nav-tabs {
  border-bottom: none;
}

a:hover {
  text-decoration: none;
}

.text-danger {
  font-size: 0.75rem;
}

.contenedor-cliente {
  padding-left: 5px;
}

.contenedor-cliente--principal {
  padding-top: 15px;
}

.bg-gray-wran {
  background-color: #c7cbd6;
}

.minHeightInfo {
  height: 192px;
}

.img-login {
  width: 200px;
  margin-top: -100px;
}

.table-global {
  font-size: 0.9rem;
}

.table-estados-orden {
  font-size: 0.8rem;
}

.table-global tbody tr,
.table-global tfoot tr {
  height: 35px;
  background-color: #fff;
}

.form-control,
.input-group-text {
  padding: 0.1rem 0.2rem;
  font-size: 0.8rem;
}

.table-global thead th {
  font-weight: 500;
}

.table-global thead th.detalle-carga-th {
  width: 450px;
}

.table-global thead th.detalle-opciones {
  width: 85px;
}

textarea {
  resize: none;
}

.list-group-item {
  border: none;
  font-weight: 500;
  background: #eaf0fc;
}

.card {
  border-radius: 0px;
}

.minHeightTotal {
  height: 97px;
}

.border-panel {
  border: 1px solid #d9c7c7;
}

.table-responsive {
  max-height: 700px;
  /**max-width: 1087px;*/
}

#table-orden {
  font-size: 13px;
  cursor: pointer;
}

.btn-outline-warning {
  color: #ff751a;
  border-color: #ff751a;
  font-weight: 600;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ff751a;
  border-color: #ff751a;
}

.btn-outline-warning.focus:enabled,
.btn-outline-warning:focus:enabled {
  box-shadow: 0 0 0 0.2rem #ff751a;
}

.btn-outline-warning:hover:enabled {
  color: #fff;
  background-color: #ff751a;
  border-color: #ff751a;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ff751a;
  border-color: #ff751a;
}

.btn-sin-border-radius {
  border-radius: 0px;
}

.fw-600 {
  font-weight: 600;
}

.margin-panel {
  margin-right: -15px;
}

.text-orange {
  color: #ff751a;
}

.min-height-80 {
  min-height: 80vh;
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #f39c63;
  border-color: #f39c63;
}

.react-datepicker-wrapper {
  display: block;
}

.font-s-8 {
  font-size: 0.8rem;
}

.line-division {
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: 1px solid #2880e4;
}

.font-s-10 {
  font-size: 0.9rem;
}

#table-orden tbody {
  background: #fff;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff751a;
}

.card-retiro {
  border-radius: 5px;
}

.card-retiro .card-header-white {
  background-color: #fff;
}

.rdt_TableHeadRow {
  background: #d1cfd0 !important;
}

.gydTdW {
  border: 1px solid #e3e3e3 !important;
}

.kGsMTV {
  font-size: 18px !important;
  min-height: 30px !important;
}

.kKRXzR {
  font-weight: 700 !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  font-weight: 600;
  background-color: #2880e4;
  border-color: #2880e4;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #666;
  font-weight: 600;
  background-color: #d1cfd0;
  border-color: #d1cfd0;
}

.gSsTsI {
  padding: 0 !important;
}

.fs-12 {
  font-size: 12px;
}

.width-icono {
  width: 13px;
}

.fs-15 {
  font-size: 14px;
  padding-left: 10px;
}

.width-icono-entrega {
  width: 22px;
  margin-left: -4px;
}

.fs-entrega {
  font-size: 14px;
  padding-left: 5px;
}

.text-celeste {
  color: #23447e;
}

.fs-18 {
  font-size: 18px;
}

.form-control-search {
  height: calc(1.5em + 0.75rem + 7px);
}

.div-copyright {
  font-size: 8px;
  position: relative;
  bottom: 0;
  width: 100%;
}
.btn-large-opcion {
  width: 180px;
}

.form-check label {
  font-weight: 400;
}

.padding-user {
  padding-left: 150px;
  padding-right: 150px;
  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

img.img-conf {
  width: 15px;
}

.btn-size-opciones {
  width: 130px;
}

.text-size {
  font-size: 15px;
}

.text-weigth {
  font-weight: 500;
}

#frmFacturacion .form-check label {
  font-weight: 500;
}

.tLhxY,
.kGsMTV,
.gSsTsI {
  background-color: transparent !important;
}

.minHeigthSinResultado {
  min-height: 300px;
}

.minHeigthSinResultado .row-sin-resultado {
  min-height: inherit;
  align-items: center;
}

.fs-25 {
  font-size: 25px;
}

.table-historico #cell-5-undefined div {
  color: green;
  font-weight: 500;
}

.table-nomina .table-responsive {
  max-height: 650px;
}

.img-foto-licencia {
  width: 30px;
}

.react-switch {
  top: 3px !important;
}

.small_radius-switch {
  margin-left: 8px !important;
}

.react-switch-handle {
  width: 6px !important;
}

.react-switch-bg {
  width: 105px !important;
}

.bg-fondo-home {
  background-image: url("../images/fondo_home.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.title-panel {
  margin-top: 2px;
}

.accordion .card {
  border: 0;
}

.accordion .card .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: white;
}

.accordion .card .card-header .btn.focus,
.accordion .card .card-header .btn:focus {
  box-shadow: none;
}

.accordion .card .card-header .btn-link:hover {
  color: #0056b3;
  text-decoration: none;
}

.accordion .card .card-header .btn-link.focus,
.accordion .card .card-header .btn-link:focus {
  text-decoration: none;
}

.fs-22 {
  font-size: 22px;
}

.icono-cajas {
  width: 45px;
}

.tab-cajas .card-body {
  padding: 1.25rem 0px;
}

.pad-row {
  padding-left: 40px;
}

#frmBusquedaGlobal .frmSearch {
  background-color: #fff;
}

.btn-nueva-rendicion {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.btn-nueva-rendicion button {
  border-radius: 50%;
  height: 65px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.tab-cajas .card-body .btn.focus,
.tab-cajas .card-body .btn:focus {
  box-shadow: none;
}

.tab-cajas .card-body .btn:hover,
.tab-cajas .card-body .btn-link.focus,
.tab-cajas .card-body .btn-link:focus {
  text-decoration: none;
}

.th-description {
  width: 400px;
}

.height-encabezado {
  min-height: 405px;
}

.check-guia {
  width: 20px;
  height: 20px;
}

.btn-nuevo-guia {
  width: 23px;
}

.btn-download {
  width: 15px;
}

#sidebar.active .menu-left--image a img.logo--menu {
  width: 55px;
}

.table-component {
  display: grid;
}

.table-asignar #cell-3-undefined {
  width: 700px !important;
}

.table-asignar .sc-hKgILt div:first-child {
  white-space: normal;
  overflow: hidden;
  text-overflow: initial;
}

.btn-size-opciones {
  width: 250px;
  margin-right: 12px;
}

.table-correlativo input[name="select-all-rows"] {
  display: none;
}

.btn-orden {
  width: 200px;
}

.btn-group-orden {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table-search-orden td,
.table-search-orden th {
  padding: 0.4rem;
}

.table-component input[name="select-all-rows"] {
  display: none;
}

.btn-assign {
  padding: 0.35rem 0.3rem;
}

.img-assign {
  width: 30px;
}

.solution-btn {
  width: 197px;
}

.panel-destino {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.w-label {
  width: 275px;
  text-align: right;
  padding-right: 1rem;
}

.w-input {
  width: 200px;
  margin-right: 2rem;
}

.w-button {
  width: 200px;
}

.w-group-form {
  width: 550px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.table-retiro thead,
.table-retiro tbody {
  font-size: 13px;
}

.card-retiro-completed {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-preorden {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.btn-w-95 {
  min-width: 95px;
}

.table-retiro-responsive {
  max-width: 100% !important;
}

.receive-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 800px;
  margin: 20px auto 0;
}

.receive-item {
  width: 150px;
  height: 81px;
  background: #fff;
  border: 1px solid #dee2e6 !important;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  margin-bottom: 10px;
}

.receive-title {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 700;
  color: #007bff;
  line-height: 1rem;
}

.receive-subtitle {
  font-size: 20px;
  font-weight: 400;
}

.warning-tr {
  background-color: #f1ff1a !important;
  color: #282828;
}

.text-warning {
  color: #f1ff1a !important;
}

.success-tr {
  background-color: #28a745 !important;
  color: white;
}

.text-success {
  color: #28a745 !important;
}

.modal-spinner .modal-content {
  background: transparent !important;
  border: none;
}

.options-btn__print {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: 50px auto;
}

.btn-print {
  height: 50px;
  font-size: 20px;
}

.txt-print {
  text-align: center;
  width: 80%;
  margin: 17px auto 0;
}

.red-tr {
  background: #ff2b2b !important;
  color: white;
}

.mtop-1 {
  margin-top: 1.5rem;
}

.card-header__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-datepicker-popper {
  z-index: 100;
}

.table-invoice__doc {
  font-size: 13.5px;
}

.background-muted {
  background: #6c757d4d;
}

.table-config-fact {
  font-size: 0.75rem !important;
}

.btn-size {
  width: 250px;
}

.d-group-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-group-button {
  display: flex;
  align-items: center;
  width: 500px;
  justify-content: flex-start;
}

.text-orange {
  color: var(--orange);
  font-weight: 700;
}

.text-loading-pdf {
  font-size: 1.4rem;
  color: var(--color-orange);
  margin-top: -4rem;
}

.container-pdf {
  position: absolute;
  right: 0;
  left: 0;
  top: 10px;
  background: var(--color-white);
  padding-top: 15rem;
  text-align: center !important;
  margin-bottom: 3rem !important;
  padding-bottom: 3rem !important;
}

.text-count-destination {
  font-weight: bold;
  font-size: 1.43em;
}

.btn-select-multiple {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  color: #323232;
  font-size: 12.8px;
  border: 1px solid #c3c3c3;
}

.btn-select-multiple:not(:disabled):not(.disabled).active,
.btn-select-multiple:not(:disabled):not(.disabled):active,
.show > .btn-select-multiple.dropdown-toggle {
  color: #fff;
  font-weight: 400;
  background-color: #ffffff;
  border: 1px solid #c3c3c3;
  color: black;
}

.btn-select-multiple.focus,
.btn-select-multiple:focus,
.btn-select-multiple:hover {
  color: #000;
  background-color: #ffffff;
  border: 1px solid #c3c3c3;
  box-shadow: none;
}

.table-responsive .table-filtered {
  height: auto;
  overflow: scroll;
}

.table-filtered thead tr:nth-child(1) th {
  background: #dee2e6;
  border: 1px solid #dee2e6;
  position: sticky;
  top: -2px;
  z-index: 10;
}

.pagination {
  justify-content: flex-end;
  margin-top: 20px;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #ff751a !important;
  border-color: #ff751a !important;
}

.page-link {
  color: #7b7b7c !important;
}

.fsearch-retiro {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.LoadingUserScreen {
  background-color: #f5f0eb;
  position: fixed;
  z-index: 10000000000000;
  opacity: 1;
  width: calc(100vw);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in 200ms;
}

.LoadingUserScreenOpacityNone {
  opacity: 0;
}

.LoadingUserScreenNone {
  display: none;
}

.LoadingContainer img {
  position: absolute;
  margin-left: 48px;
  margin-top: 61.5px;
}

.LoadingCircle {
  opacity: 1;
  width: 15rem;
  height: 15rem;
  color: #342580;
}

.text-padding {
  padding: 0.4rem 0.2rem 0.1rem;
}

.visible-password {
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
}

.p-relative {
  position: relative;
}

::-ms-reveal {
  display: none;
}

.row-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.line-bottom {
  border-bottom: 0.5px solid #dbdbdb;
  margin-bottom: 16px;
  margin-top: 16px;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ff751a;
  border-color: #ff751a;
}

.flex-grow-1 {
  flex-grow: 1;
}

.bg-pointer {
  width: 100%;
  position: absolute;
  font-size: 13px;
  text-align: center;
  line-height: 14px;
  left: 0%;
  bottom: 0%;
  right: 0%;
  height: 100%;
  top: 0%;
  padding-top: 7%;
  transition: 0.5s ease-out;
  color: transparent;
}

.bg-photo-success {
  height: 90px;
}

.bg-photo-success:hover .bg-pointer {
  background-color: rgb(33 33 33 / 70%);
  color: white;
  cursor: pointer;
}

.mx-auto-circle {
  margin: 0 auto 1rem;
  text-align: center;
}

.btn-send {
  padding: 6px 10px;
}

.menu-card,
.menu-card .card-header {
  background-color: #23447e !important;
}

.menu-card .accordion-header {
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
  padding-right: 16px;
}

.menu-card .accordion-header.disabled {
  color: #989898;
}

.menu-card .accordion-header img {
  margin-right: 10px;
  width: 19px;
  height: 19px;
  object-fit: scale-down;
}

.menu-card .card-header .accordion-header:hover {
  color: white !important;
}

.menu-card .accordion-header p {
  margin-bottom: 0px;
}

.accordion-header__selected {
  background-color: #fa771d;
}

.accordion-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
}

.menu-card .nav-item-menu {
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 7px;
  text-align: left;
}

.menu-card .nav-item-menu:hover span {
  color: #fb761d !important;
}

.accordion-quote .nav-link {
  padding: 1rem 1rem 0.8rem;
}

.menu-card .card-body {
  padding: 1rem 1.25rem 0.5rem;
}

.menu-card .nav-menu {
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 0px;
  color: white;
  cursor: pointer;
}

.menu-card .nav-item-menu__selected {
  color: #fb761d;
}

.sidebar-menu {
  flex-grow: 1;
}

.accordion-header a {
  display: block;
  width: 100%;
  height: 100%;
}

.btn-orden[disabled] {
  cursor: not-allowed;
}

.menu-card .disabled .nav-text {
  color: #989898 !important;
}

.btn-invitation {
  border-radius: 50% !important;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-top: 7px;
}
